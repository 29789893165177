import { useQuery } from '@apollo/client'
import { Spinner } from '@chakra-ui/react'
import { Button, Grid } from '@mui/material'
import Text from '@mui/material/Typography'
import { loader } from 'graphql.macro'
import { useContext } from 'react'
import { IDContext, UpdateLoginContext } from '../utils/context'
import TitleBar from '../TitleBar'

interface IEmployeeQueryResult {
  employee: {
    firstName: string
    lastName: string
    imageUrl: string
  }
}

const employeeQuery = loader('../../graphql/queries/GetEmployee.graphql')

const EmployeeInfo: React.FC = () => {
  const employeeId = useContext(IDContext) as string
  const { clearLogin } = useContext(UpdateLoginContext)
  const { data, loading } = useQuery<IEmployeeQueryResult>(employeeQuery, {
    variables: { id: employeeId },
  })

  if (loading) {
    return <Spinner size="xs" />
  }

  const { firstName, lastName, imageUrl } = data!.employee

  return (
    <div>
      <TitleBar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <div>
            <img src={imageUrl} alt="Profile" />
            <Text>
              Logged in as {firstName} {lastName}
            </Text>
            <Button variant="outlined" color="primary" onClick={clearLogin}>
              Log Out
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default EmployeeInfo
