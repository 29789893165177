import EmployeeAccount from '../components/Account/EmployeeAccount'
import LocalLoginGate from '../components/Login/LocalLoginGate'

const Account = () => (
  <LocalLoginGate>
    <EmployeeAccount />
  </LocalLoginGate>
)

export default Account
