import { useCookies } from 'react-cookie'
import useAsyncState from '../hooks/useAsyncState'
import {
  IDContext,
  TokenContext,
  UpdateLoginContext,
  ValidContext,
} from '../components/utils/context'

interface IProps {
  tokenName: string
  idName: string
  domain: string
  children: React.ReactNode
}

const LoginProvider = ({ tokenName, idName, domain, children }: IProps) => {
  const [valid, setValid] = useAsyncState(false)
  const [cookies, setCookie, removeCookie] = useCookies([tokenName, idName])

  const setLogin = (token: string, id: string) => {
    setCookie(tokenName, token, { domain, path: '/', sameSite: 'lax' })
    setCookie(idName, id, { domain, path: '/', sameSite: 'lax' })
    return setValid(true)
  }

  const clearLogin = () => {
    removeCookie(tokenName, { domain, path: '/', sameSite: 'lax' })
    removeCookie(idName, { domain, path: '/', sameSite: 'lax' })
    setValid(false)
  }

  return (
    <TokenContext.Provider value={cookies[tokenName] || null}>
      <IDContext.Provider value={cookies[idName] || null}>
        <ValidContext.Provider value={valid}>
          <UpdateLoginContext.Provider value={{ setLogin, clearLogin }}>
            {children}
          </UpdateLoginContext.Provider>
        </ValidContext.Provider>
      </IDContext.Provider>
    </TokenContext.Provider>
  )
}

export default LoginProvider
