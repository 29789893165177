import * as React from 'react'

const createDefaultCallback =
  (setterName: string): (() => Promise<void>) =>
  async () => {
    // eslint-disable-next-line no-console
    console.error(
      `${setterName} is not in context! Did you forget to wrap your app in a LoginProvider?`
    )
  }

export const TokenContext = React.createContext<string | null>(null)

export const IDContext = React.createContext<string | null>(null)

export const ValidContext = React.createContext<boolean>(false)

export interface IUpdateLogin {
  setLogin: (token: string, id: string) => Promise<void>
  clearLogin: () => void
}

export const UpdateLoginContext = React.createContext<IUpdateLogin>({
  setLogin: createDefaultCallback('setLogin'),
  clearLogin: createDefaultCallback('clearLogin'),
})
