import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

interface IProps {
  to: string
  Icon: React.ComponentType<any>
  primary: string
  secondary: string
  onClick: React.MouseEventHandler
}

const NavigatorItem: React.FC<IProps> = ({
  to,
  Icon,
  primary,
  secondary,
  onClick,
}) => {
  return (
    <NavLink
      to={to}
      style={{
        textDecoration: 'underline',
      }}
    >
      <ListItem button={true} onClick={onClick}>
        <ListItemAvatar>
          <Avatar>
            <Icon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    </NavLink>
  )
}

export default NavigatorItem
