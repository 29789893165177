import { useEffect } from 'react'

interface IProps {
  to: string
}

const LocationRedirect: React.FunctionComponent<IProps> = ({ to }) => {
  useEffect(() => {
    window.location.replace(to)
  })
  return null
}

export default LocationRedirect
