import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import cookie from 'js-cookie'
import { apiUrl, tokenName } from './config'

const cache = new InMemoryCache()
const httpLink = createHttpLink({
  uri: apiUrl + '/graphql',
})
const authLink = setContext((_, { headers }) => {
  const token = cookie.get(tokenName)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
})
