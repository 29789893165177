import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Text from '@mui/material/Typography'
import React, { useState } from 'react'
import Navigator from './Navigator'

const styles = {
  appBar: {
    flexGrow: 0,
  },
}

const TitleBar: React.FC = () => {
  const [navigatorOpen, setNavigatorOpen] = useState(false)

  return (
    <React.Fragment>
      <AppBar position="static" sx={styles.appBar}>
        <Toolbar>
          <IconButton
            sx={{ marginRight: 2 }}
            color="inherit"
            onClick={() => setNavigatorOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Text variant="h5" color="inherit">
            Ardent Account
          </Text>
        </Toolbar>
      </AppBar>
      <Navigator open={navigatorOpen} onClose={() => setNavigatorOpen(false)} />
    </React.Fragment>
  )
}

export default TitleBar
