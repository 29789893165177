import { Navigate, useLocation } from 'react-router-dom'
import LocationRedirect from '../components/LocationRedirect'
import LoginGate from '../components/Login/LoginGate'

const VerifyToken = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  return (
    <LoginGate
      RedirectComponent={Navigate}
      redirectTo={{
        pathname: '/login',
        search: location.search,
      }}
    >
      {searchParams.has('from') ? (
        <LocationRedirect to={searchParams.get('from')!} />
      ) : (
        <Navigate to="/account" />
      )}
    </LoginGate>
  )
}

export default VerifyToken
