import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Text from '@mui/material/Typography'
import { loader } from 'graphql.macro'
import { FormEvent, useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UpdateLoginContext } from '../utils/context'
import { Grid } from '@mui/material'
import TitleBar from '../TitleBar'

interface ILoginResult {
  employeeLogin: {
    success: boolean
    message: string
    token: string
    employee: {
      id: string
    }
  }
}

const styles = {
  button: {
    alignSelf: 'center',
    mb: 2,
  },
  field: {
    mb: 2,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
  },
}

const loginMutation = loader('../../graphql/mutations/EmployeeLogin.graphql')

const EmployeeLogin: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<false | string>(false)
  const { setLogin } = useContext(UpdateLoginContext)
  const [login] = useMutation<ILoginResult>(loginMutation, {
    variables: { email, password },
  })
  const location = useLocation()

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      try {
        const { data, errors } = await login()

        if (errors) throw errors

        const { success = false, token, employee } = data!.employeeLogin

        if (!success) {
          setError('Incorrect email or password')
          return
        }

        await setLogin(token, employee.id)

        const search = new URLSearchParams(location.search)

        if (search.has('from')) {
          window.location.href = search.get('from')!
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        setError(
          'Unepxected error when contacting server. Please try again later.'
        )
      }
    },
    [location.search, login, setLogin]
  )

  return (
    <form onSubmit={onSubmit}>
      <TitleBar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Paper sx={styles.paper}>
            <Text variant="h5">Log In</Text>
            <TextField
              sx={styles.field}
              label="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.currentTarget.value)
                setError(false)
              }}
            />
            <TextField
              sx={styles.field}
              label="Password"
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.currentTarget.value)
                setError(false)
              }}
            />
            {error ? <Text color="error">{error}</Text> : null}
            <Button
              sx={styles.button}
              color="primary"
              variant="contained"
              type="submit"
            >
              Log In
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </form>
  )
}

export default EmployeeLogin
