import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import LoginGate from './LoginGate'

const LocalLoginGate: React.FC<{
  children: ReactNode
}> = ({ children }) => (
  <LoginGate
    RedirectComponent={Navigate}
    redirectTo={{
      pathname: '/login',
      search: `from=${encodeURIComponent(window.location.href)}`,
    }}
  >
    {children}
  </LoginGate>
)

export default LocalLoginGate
