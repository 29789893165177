import { default as AccountIcon } from '@mui/icons-material/Face'
import { default as LoginIcon } from '@mui/icons-material/LockOpen'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import React, { useContext } from 'react'
import { TokenContext } from '../utils/context'
import NavigatorItem from './NavigatorItem'

interface IProps {
  open: boolean
  onClose: () => void
}

const Navigator: React.FC<IProps> = ({ open, onClose }) => {
  const token = useContext(TokenContext)

  return (
    <Drawer open={open} onClose={onClose}>
      <List>
        {token ? (
          <NavigatorItem
            to="/account"
            Icon={AccountIcon}
            primary="Account"
            secondary="View your account info"
            onClick={onClose}
          />
        ) : (
          <NavigatorItem
            to="/login"
            Icon={LoginIcon}
            primary="Log In"
            secondary="Manage your login status"
            onClick={onClose}
          />
        )}
        <Divider />
      </List>
    </Drawer>
  )
}

export default Navigator
