import * as React from 'react'

const useAsyncState = <S>(
  initialState: S | (() => S)
): [S, (newState: React.SetStateAction<S>) => Promise<void>] => {
  const [state, setState] = React.useState(initialState)

  // FIXME Executing useMemo here breaks login. I should consider reworking the login pipeline.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resolvers: Array<() => void> = []

  React.useEffect(() => {
    while (resolvers.length > 0) {
      const resolve = resolvers.shift()
      resolve?.()
    }
  }, [resolvers, state])

  return [
    state,
    (newState: React.SetStateAction<S>) => {
      setState(newState)
      return new Promise((resolve) => resolvers.push(resolve))
    },
  ]
}

export default useAsyncState
