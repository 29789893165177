import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import EmployeeLogin from '../components/Login/EmployeeLogin'
import { TokenContext } from '../components/utils/context'

const Login = () => {
  const location = useLocation()
  const token = useContext(TokenContext)

  if (token) {
    return (
      <Navigate
        to={{
          pathname: `/verifyToken`,
          search: location.search,
        }}
      />
    )
  }

  return <EmployeeLogin />
}

export default Login
