import { useMutation } from '@apollo/client'
import { Spinner } from '@chakra-ui/react'
import { loader } from 'graphql.macro'
import React, { useContext, useEffect, useState } from 'react'
import LocationRedirect from '../LocationRedirect'
import {
  TokenContext,
  UpdateLoginContext,
  ValidContext,
} from '../utils/context'

interface IProps {
  redirectTo: any
  RedirectComponent?: React.ComponentType<{ to: any }>
  children: React.ReactNode
}

interface IVerifyResult {
  success: boolean
  token: string
  employee: {
    id: string
  }
}

const verifyMutation = loader('../../graphql/mutations/VerifyToken.graphql')

const LoginGate: React.FC<IProps> = (props: IProps) => {
  const [loading, setLoading] = useState(true)
  const valid = useContext(ValidContext)
  const token = useContext(TokenContext)
  const { setLogin, clearLogin } = useContext(UpdateLoginContext)
  const [verifyToken] = useMutation<{ employeeVerify: IVerifyResult }>(
    verifyMutation
  )

  useEffect(() => {
    const validateToken = async () => {
      try {
        const { data, errors } = await verifyToken({ variables: { token } })

        if (errors || !data || !data.employeeVerify.success) {
          clearLogin()
          return
        }

        const { employeeVerify } = data
        setLogin(employeeVerify.token, employeeVerify.employee.id)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        clearLogin()
      } finally {
        setLoading(false)
      }
    }

    validateToken()
    // Intentional dep array to mimic cDM
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { redirectTo, RedirectComponent = LocationRedirect, children } = props

  if (loading) return <Spinner size="sm" />
  if (!valid) return <RedirectComponent to={redirectTo} />
  return React.Children.only(children) as React.ReactElement
}

export default LoginGate
