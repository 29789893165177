const {
  NODE_ENV,
  REACT_APP_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_TOKEN_NAME,
  REACT_APP_ID_NAME,
} = process.env

export const dev = REACT_APP_NODE_ENV !== 'production'

export const apiUrl =
  REACT_APP_API_HOST ||
  (dev
    ? 'https://ardent-api-next.ardentlabs.io'
    : 'https://ardent-api.ardentlabs.io')

export const tokenName: string = REACT_APP_TOKEN_NAME || 'ardentoken'
export const idName: string = REACT_APP_ID_NAME || 'employeeId'

// Use NODE_ENV here to determine whether this is hosted in a dev server or built and served locally
export const domain = NODE_ENV !== 'production' ? 'localhost' : 'ardentlabs.io'
