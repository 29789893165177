import { ApolloProvider } from '@apollo/client'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { client } from './apollo'
import Client from './components/Client'
import LoginProvider from './providers/LoginProvider'
import TitleBar from './components/TitleBar'
import { domain, idName, tokenName } from './config'
import Login from './pages/login'
import Account from './pages/account'
import VerifyToken from './pages/verify-token'

const theme = createTheme({
  palette: {
    contrastThreshold: 2.5,
    primary: {
      main: '#00adef',
    },
  },
})

function App() {
  return (
    <Client>
      <ApolloProvider client={client}>
        <CookiesProvider>
          <LoginProvider tokenName={tokenName} idName={idName} domain={domain}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<TitleBar />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/verifyToken" element={<VerifyToken />} />
                  <Route path="/account" element={<Account />} />
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </LoginProvider>
        </CookiesProvider>
      </ApolloProvider>
    </Client>
  )
}

export default App
